import React from 'react'
import '../styles/footer.css'

function footer() {
  return (
    <div className='Footer'>
        <p> &copy; 2023 AlterNet.co.il </p>
    </div>
  )
}

export default footer