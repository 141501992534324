import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/login.css';
import Navbar from './navbar';
import Footer from './footer';

function Login() {
  const params = useParams();
  const [cityParam, typeParam, itemParam] = params.params.split('-');
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [activeButton, setActiveButton] = useState('login');
  const navigate = useNavigate();
  const [loginFormValues, setLoginFormValues] = useState({
    name: '',
    password: '',
  });
  const [isLoginFormValid, setIsLoginFormValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleButtonClick = (value) => {
    setShowLoginForm(value);
    setActiveButton(value ? 'login' : 'signin');
    setErrorMessage(null); // reset error message on form switch
  };

  const handleLoginInputChange = (e) => {
    const { name, value } = e.target;
    setLoginFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessage(null); // reset error message on input change
  };

  useEffect(() => {
    const { name: loginName, password } = loginFormValues;
    setIsLoginFormValid(loginName === 'AlterNet.com' && password === '12345');
  }, [loginFormValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoginFormValid) {
      navigate('/hero', {
        state: { city: cityParam, type: typeParam, item: itemParam },
      });
    } else {
      setErrorMessage('Invalid Email or Password');
    }
  };

  return (
    <>
      <Navbar />
      <div className='content-container'>
        <div className='form-container'>
          <div className='button-Container'>
            <button
              className={`form-Buttons ${activeButton === 'login' ? 'active' : ''}`}
              onClick={() => handleButtonClick(true)}
            >
              התחברות
            </button>
            <button
              className={`form-Buttons ${activeButton === 'signin' ? 'active' : ''}`}
              onClick={() => handleButtonClick(false)}
            >
              הרשמה
            </button>
          </div>
          {showLoginForm ? (
            <form id='loginForm' onSubmit={handleSubmit}>
              <input
                type='text'
                id='nameLogin'
                placeholder='Email... *'
                name='name'
                value={loginFormValues.name}
                onChange={handleLoginInputChange}
                required
              />
              <input
                type='password'
                placeholder='Password... *'
                id='passwordLogin'
                name='password'
                value={loginFormValues.password}
                onChange={handleLoginInputChange}
                required
              />
              {errorMessage && <div className='error-message'>{errorMessage}</div>}
              <div className='rememberForgot'>
                <button>?שכחתי סיסמה </button>
              </div>
              <button className='formButton' type='submit'>
                אישור
              </button>
            </form>
          ) : (
            <form id='signForm'>
              <input
                type='text'
                id='namesign'
                placeholder='Full Name *'
                name='name'
                value={loginFormValues.name}
                onChange={handleLoginInputChange}
                required
              />
              <input
                type='number'
                id='namesign'
                placeholder='Phone Number *'
                name='phone'
                value={loginFormValues.phone}
                onChange={handleLoginInputChange}
                required
              />
              <input
                type='email'
                placeholder='Email *'
                id='passwordLogin'
                name='password'
                value={loginFormValues.email}
                onChange={handleLoginInputChange}
                required
              />
              <input
                type='password'
                placeholder='Password *'
                id='passwordLogin'
                name='password'
                value={loginFormValues.passwordd}
                onChange={handleLoginInputChange}
                required
              />
              {errorMessage && <div className='error-message'>{errorMessage}</div>}
              <button className='formButton' type='submit'>
                הבא
              </button>
            </form>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
