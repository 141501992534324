import React, { useEffect, useState, useRef  } from 'react';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import { useLocation } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cam4 from '../assets/cam04.png'
import cam3 from '../assets/cam03.jpg'
import '../styles/hero.css';


export const TypeList = [
    {
        id: 'CAM',
        type: 'מצלמה',
    },
    {
        id: '002',
        type: 'כריזה'
    },
];

export const cityList = [
    {
        id: '12000',
        name: 'מודיעין',
    },
    {
        id: '83000',
        name: 'ראשון לציון'
    }
];

export const cameraList = [
    {
        id: '14543',
        type: 'מצלמת רחוב',
        lastFixDate: '25/06/2023',
        installDate: '28/02/2020',
    },
    {
        id: '34563',
        type: 'מצלמת אבטחה',
        lastFixDate: '23/04/2023',
        installDate: '21/01/2013',
        long: '31.947173',
        lat: '34.801925',
        installHeight: '3.15 מ',
        wayOfI: 'קיר מבנה',
        manifactureName: 'Xiaomi',
        model: 'Xiaomi Mi 360',
        rezo: '2K',
        supply: 'eline',
        warrantyDate: '28/02/2024',
    }
];

export const engineList = [
    {
        id: '001',
        type: 'PAS',
        num: '15783',
        lastFixDate: '28/02/2023',
        installDate: '25/02/2018',
    },
    {
        id: '002',
        type: 'PAS-2',
        num: '56754',
        lastFixDate: '12/06/2023',
        installDate: '20/03/2017',
    }
];

function Hero() {
  const location = useLocation();
  const heroRef = useRef(null);

  const [type, setType] = useState(null);
  const [city, setCity] = useState(null);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const scrollToTop = () => {
      if (heroRef.current) {
        const offset = 100; // Adjust the offset value as needed
        const topPosition = heroRef.current.getBoundingClientRect().top;
        window.scrollTo({
          top: topPosition - offset,
          behavior: 'smooth',
        });
      }
    };

    if (window.location.pathname === '/hero') {
      setTimeout(scrollToTop, 100); // Add a slight delay before scrolling
    }
  }, []);

  useEffect(() => {
    console.log('Received state:', location.state);
    const { city, type, item } = location.state || {};
      
    const typeObject = TypeList.find(obj => obj.id === type);
    setType(typeObject);

    const cityObject = cityList.find(obj => obj.id === city);
    setCity(cityObject);

    let itemObject = null;
    if (typeObject && typeObject.type === 'מצלמה') {
      itemObject = cameraList.find(obj => obj.id === item);
    } else if (typeObject && typeObject.type === 'כריזה') {
      itemObject = engineList.find(obj => obj.id === item);
    }
    setItem(itemObject);
  }, [location.state]);

  
    return (
    <>
    <Navbar/>
      <div className='Hero' id='hero' ref={heroRef}>
        <div className='data-container'>
          <div className='mainData'>
            <div className='data'>
              <div className='main-item'>
                  <h1>{city ? city.name : ''}</h1>
                  <p>  :עיר </p>
                </div>
                <div className='main-item'>
                  <h1>{type ? type.type : ''}</h1>
                  <p> :רכיב </p>
                </div>
                <div className='main-item'>
                  <h1>{item ? item.type : ''}</h1>
                  <p> :תת רכיב  </p>
                </div>
            </div>
              <div className='images'>
                <Carousel showThumbs={false} emulateTouch>
                  <div>
                    <img src={cam4} alt='' width={150} height={150} />
                  </div>
                  <div>
                    <img src={cam3} alt='' width={150} height={150} />
                  </div>
              </Carousel>
              </div>
          </div>

            <div className='moreInfo'>
              <h1> :מידע על הרכיב </h1>
              <div className='more-item'>
                <h2>{item ? item.id : ''}</h2>
                <p> :מספר סידורי</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.lastFixDate : ''}</h2>
                <p>:תאריך טיפול אחרון</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.long : ''}</h2>
                <p>:LONG</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.lat : ''}</h2>
                <p>:LAT </p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.installHeight : ''}</h2>
                <p>:גובה ההתקנה</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.wayOfI : ''}</h2>
                <p>:אופן ההתקנה</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.manifactureName : ''}</h2>
                <p>:יצרן</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.model : ''}</h2>
                <p>:דגם</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.rezo : ''}</h2>
                <p>:רזולוציה</p>
              </div>
              <div className='more-item'>
                <h2>{item ? item.warrantyDate : ''}</h2>
                <p>: תאריך סיום אחריות יצרן</p>
              </div>
            </div>
        </div>
      </div>
      <Footer/>
    </>
    
  );
}

export default Hero;
