import React from 'react'
import '../styles/welcome.css'
import welcome_back from '../assets/tel_aviv_ver.jpg'
import Navbar from './navbar'
import Footer from './footer'


function welcome() {
  return (
    <>
    <Navbar />
        <div className='welcome'>
            <div className='header'>
                <h1>הופכים רעיון למציאות</h1>
                <p>חברת אלתרנט הינה חברת בוטיק המתמחה ביעוץ אסטרטגי וניהול פרויקטים ותהליכים בעולמות של חדשנות, בשלושה ורטיקלים עיקריים: ערים חכמות, תעשייה חכמה ובריאות חכמה</p>
            </div>
            <img src={welcome_back} alt="" />
        </div>
        <Footer />
    </>
  )
}

export default welcome