import React , { useState } from 'react'
import '../styles/navbar.css'
import Menuicon from '@mui/icons-material/Menu';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Link as ScrollLink} from 'react-scroll';
import { Link as RouterLink, useLocation} from 'react-router-dom';
import logo from '../assets/LOGO_invert.png';



function Navbar() {
    const [openLinks, setOpenLinks] = useState(false);
    const location = useLocation();
    const toggleNavbar = () => {
        setOpenLinks(!openLinks);
    };

  return (
    <div className='Navbar' id={openLinks ? 'open' : 'close'}>
        <div className='left-side'>
        <div className='links'>
                <ScrollLink
                    to="hero"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={toggleNavbar}
                    className={location.pathname === '/Hero' ? 'active' : ''}
                >
                <RouterLink to="/" style={{ textDecoration: 'none' }}> <b>בית</b> </RouterLink>
                </ScrollLink>

                <ScrollLink
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-58}
                    duration={500}
                    onClick={toggleNavbar}
                    className={location.pathname === '/' ? 'active' : ''}
                >
                    <b> היסטוריית חיפוש </b>
                </ScrollLink>

                <ScrollLink
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-58}
                    duration={500}
                    onClick={toggleNavbar}
                    className={location.pathname === '/' ? 'active' : ''}
                >
                    <b> אודות </b>
                </ScrollLink>

                <ScrollLink
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-58}
                    duration={500}
                    onClick={toggleNavbar}
                    className={location.pathname === '/' ? 'active' : ''}
                >
                    <b>צור קשר</b>
                </ScrollLink>
            </div>
            <button className="menu-icon" onClick={toggleNavbar}>
            {openLinks ? <ClearOutlinedIcon /> : <Menuicon />}
            </button>

        </div>
        <div className='right-side'>
            <ScrollLink
                to="hero"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                
                >
                <RouterLink to="/" style={{ textDecoration: 'none' }}>
                    <img src={logo} width={120} height={40} alt=''/>
                </RouterLink>
            </ScrollLink>
        </div>
    </div>
  )
}

export default Navbar