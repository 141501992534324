import React from 'react'
import '../styles/test.css'
import Navbar from './navbar'
import Footer from './footer'


function Test() {
  return (
    <>
    <Navbar/>
    <div className='testo'>
        <h1>testtttttttttttttttttttttttttt</h1>
    </div>
    <Footer/>
    </>
  )
}

export default Test