import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import Hero from './components/hero';
import NotFound from './components/notFound';
import Login from './components/login';
import Test from './components/test';


ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/:params" element={<Login />} />
      <Route path="/test" element={<Test />} />
      <Route path="/hero" element={<Hero />} />
      <Route path="*" element={<NotFound />} /> 
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
